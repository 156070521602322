"use strict";

// Class definition
var KTSigninGeneral = function () {
    // Elements
    var form;
    var message_invalid_email;
    var message_invalid_password;
    var validator;

    // Handle form
    var handleForm = function (e) {
        // Init form validation rules. For more info check the FormValidation plugin's official documentation:https://formvalidation.io/
        validator = FormValidation.formValidation(
            form,
            {
                fields: {
                    'email': {
                        validators: {
                            notEmpty: {
                                message: translations.invalid_email
                            },
                            regexp: {
                                regexp: '^[^@\\s]+@([^@\\s]+\\.)+[^@\\s]+$',
                                message: translations.invalid_email
                            }
                        }
                    },
                    'password': {
                        validators: {
                            notEmpty: {
                                message: translations.invalid_password
                            },
                            regexp: {
                                regexp: '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})',
                                message: translations.invalid_password
                            }
                        }
                    }
                },
                plugins: {
                    trigger: new FormValidation.plugins.Trigger(),
                    bootstrap: new FormValidation.plugins.Bootstrap5({
                        rowSelector: '.fv-row'
                    })
                }
            }
        );
    }

    // Public functions
    return {
        // Initialization
        init: function () {
            form = document.querySelector('#sign_in_form');
            handleForm();
        }
    };
}();

// On document ready
KTUtil.onDOMContentLoaded(function () {
    KTSigninGeneral.init();
});
